import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/host.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import * as Icon from 'react-bootstrap-icons';

function Host(props) {

  return (<div className="container" style={{paddingBottom:'5px'}}>
    <footer className="pt-4 my-md-5 pt-md-5 border-top">
      <div className="row">
        <div className="col-6 col-md">
          <LazyLoadImage className="mb-2" src={props.logo} alt="" style={{width:'150px'}}/>
          <small className="d-block mb-3" style={{color: props.color, fontWeight: 'bold'}}>© 2024</small>
          <p><Link  style={{color: props.color, paddingLeft: '10px'}} to="https://www.youtube.com/@applitecl" target="_blank"><Icon.Youtube size={24}></Icon.Youtube></Link>
          <Link  style={{color: props.color, paddingLeft: '10px'}} to="https://www.instagram.com/app.lite.chile" target="_blank"><Icon.Instagram size={24}></Icon.Instagram></Link>
          <Link  style={{color: props.color, paddingLeft: '10px'}} to="https://www.facebook.com/profile.php?id=61554394417815" target="_blank"><Icon.Facebook size={24}></Icon.Facebook></Link>
         <Link  style={{color: props.color, paddingLeft: '10px'}} to="mailto:applite.chile@gmail.com"><Icon.MailboxFlag size={24}></Icon.MailboxFlag> applite.chile@gmail.com</Link></p>
        </div>
        <div className="col-6 col-md">
          <h5 style={{color: props.color, fontWeight: 'bold'}}>Categorías</h5>
          <ul className="list-unstyled text-small">
          <li><Link  style={{color: props.color, fontWeight: 'bold'}} to="https://www.applite.cl/nosotros/" target="_blank">Nosotros</Link></li>
            <li><Link  style={{color: props.color, fontWeight: 'bold'}} to="https://www.applite.cl/productos/" target="_blank">Productos</Link></li>
            <li><Link  style={{color: props.color, fontWeight: 'bold'}} to="https://www.applite.cl/proyectos/" target="_blank">Proyectos</Link></li>
            <li><Link  style={{color: props.color, fontWeight: 'bold'}} to="https://www.applite.cl/contacto/" target="_blank">Contacto</Link></li>
          </ul>
        </div>
      </div>
    </footer>
  </div>)
}

export default Host;