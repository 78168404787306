import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/circles.css'
import {Link } from 'react-router-dom';


function Circles(props) {

    return (<div className="containerCircle marketing">
        <div className="rowCircle">
          <div className="col-lg-4">
            <img className="rounded-circle" src="../assets/img/insta.png" alt="Instagram" width="140" height="140" />
            <h2>Instagram</h2>
            <p>Compartir imágenes de figuras.</p>
            <p><Link className="btn btn-secondary" to="#" role="button">+ información &raquo;</Link></p>
          </div>
          <div className="col-lg-4">
            <img className="rounded-circle" src="../assets/img/you.png" alt="Youtube" width="140" height="140" />
            {/* <h2>Youtube</h2>
            <p>Canal con información de juguetes retro, Stop Motion y algo más.</p>
            <p><Link className="btn btn-secondary" to="#" role="button">+ información &raquo;</Link></p> */}
          </div>
          <div className="col-lg-4">
            <img className="rounded-circle" src="../assets/img/face.png" alt="Facebook" width="140" height="140" />
           {/*  <h2>Facebook</h2>
            <p>Compartir imágenes de figuras.</p>
            <p><Link className="btn btn-secondary" to="#" role="button">+ información &raquo;</Link></p> */}
          </div>
          </div>
          <div className="rowCircle">
                  <div className="col-lg-4">
            <img className="rounded-circle" src="../assets/img/link.png" alt="Sitio Web" width="140" height="140" />
           {/*  <h2>Facebook</h2>
            <p>Compartir imágenes de figuras.</p>
            <p><Link className="btn btn-secondary" to="#" role="button">+ información &raquo;</Link></p> */}
          </div>
          <div className="col-lg-4">
            <img className="rounded-circle" src="../assets/img/wsp.png" alt="Whatsapp" width="140" height="140" />
           {/*  <h2>Facebook</h2>
            <p>Compartir imágenes de figuras.</p>
            <p><Link className="btn btn-secondary" to="#" role="button">+ información &raquo;</Link></p> */}
          </div>
          <div className="col-lg-4">
            <img className="rounded-circle" src="../assets/img/fono.png" alt="Teléfono" width="140" height="140" />
           {/*  <h2>Facebook</h2>
            <p>Compartir imágenes de figuras.</p>
            <p><Link className="btn btn-secondary" to="#" role="button">+ información &raquo;</Link></p> */}
          </div>
        </div>
        </div>)
}

export default Circles;

