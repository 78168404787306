import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/album.css'
import {Link } from 'react-router-dom';


function Album() {

    return (
        <div className="container">
<div className="album">
          <div className="rowT">
            <div className="col-md-4A">
              <div className="card mb-4 box-shadow">
                <img className="card-img-top" alt="PIN METALICO CLUB HE-MAN VINTAGE" src="../assets/clientes/2/productos/IMG_1489_wm_i.JPG" data-holder-rendered="true" style={{ width: '100%', display: 'block'}} />
                <div className="card-body">
                  <p className="card-text">PIN METALICO CLUB HE-MAN VINTAGE</p>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="btn-group">
                      <Link to='https://toytek.cl/products/toytek-pin-metalico-club-he-man-vintage' target='_blank' style={{paddingLeft: '5px'}}><button type="button" className="btn btn-sm btn-outline-success">Fuente</button></Link>
                      <Link to='https://mpago.la/18U6Luq' style={{paddingLeft: '5px'}}><button type="button" className="btn btn-sm btn-outline-primary">Comprar</button></Link>
                      </div>
                  </div>
                  <small className="text-precio" style={{textAlign: 'left'}}><br></br>$ 26.000.-</small>
                </div>
              </div>
            </div>
            <div className="col-md-4A">
              <div className="card mb-4 box-shadow">
                <img className="card-img-top" alt="FIGURA THUNDERCATS MUMM-RA" src="../assets/clientes/2/productos/IMG_1516_wm_i.JPG" data-holder-rendered="true" style={{ width: '100%', display: 'block'}}/>
                <div className="card-body">
                  <p className="card-text">FIGURA THUNDERCATS MUMM-RA</p>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="btn-group">
                      <Link to='https://toytek.cl/products/toytek-figura-thundercats-mumm-ra' target='_blank' style={{paddingLeft: '5px'}}><button type="button" className="btn btn-sm btn-outline-success">Fuente</button></Link>
                      <Link to='https://mpago.la/2VsdFQY' style={{paddingLeft: '5px'}}><button type="button" className="btn btn-sm btn-outline-primary">Comprar</button></Link>
                    </div>
                    
                  </div>
                  <small className="text-precio"><br></br>$ 35.000.-</small>
                </div>
              </div>
            </div>
            <div className="col-md-4A">
            <div className="card mb-4 box-shadow">
                <img className="card-img-top" alt="WATER-BASKETT-2-PLAYERS" src="../assets/clientes/2/productos/IMG_1518_wm_i.JPG" data-holder-rendered="true" style={{ width: '100%', display: 'block'}}/>
                <div className="card-body">
                  <p className="card-text">WATER BASKETT 2 PLAYERS</p>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="btn-group">
                      <Link to='https://toytek.cl/products/toytek-water-basketball-2-players' target='_blank' style={{paddingLeft: '5px'}}><button type="button" className="btn btn-sm btn-outline-success">Fuente</button></Link>
                      <Link to='https://mpago.la/1QSdVRY' style={{paddingLeft: '5px'}}><button type="button" className="btn btn-sm btn-outline-primary">Comprar</button></Link>
                    </div>
                    
                  </div>
                  <small className="text-precio"><br></br>$ 29.000.-</small>
                </div>
              </div>
            </div>

            <div className="col-md-4A">
            <div className="card mb-4 box-shadow">
                <img className="card-img-top" alt="GARGOLAS-HUDSON-KENNER-1995" src="../assets/clientes/2/productos/IMG_9867_wm_i.JPG" data-holder-rendered="true" style={{ width: '100%', display: 'block'}}/>
                <div className="card-body">
                  <p className="card-text">GARGOLAS HUDSON KENNER 1995</p>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="btn-group">
                      <Link to='https://toytek.cl/products/toytek-gargolas-hudson-kenner-1995' target='_blank' style={{paddingLeft: '5px'}}><button type="button" className="btn btn-sm btn-outline-success">Fuente</button></Link>
                      <Link to='https://mpago.la/13NEGbg' style={{paddingLeft: '5px'}}><button type="button" className="btn btn-sm btn-outline-primary">Comprar</button></Link>
                    </div>
                    
                  </div>
                  <small className="text-precio"><br></br>$ 30.000.-</small>
                </div>
              </div>
            </div>
            <div className="col-md-4A">
            <div className="card mb-4 box-shadow">
                <img className="card-img-top" alt="ROGER-RABBIT-FLEXIBLE-15-CM-DISNEY-1987" src="../assets/clientes/2/productos/IMG_9299_wm_i.JPG" data-holder-rendered="true" style={{ width: '100%', display: 'block'}}/>
                <div className="card-body">
                  <p className="card-text">ROGER RABBIT FLEXIBLE 15 CM DISNEY 1987</p>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="btn-group">
                      <Link to='https://toytek.cl/products/toytek-roger-rabbit-flexibe-15-cm-disney-1987' target='_blank' style={{paddingLeft: '5px'}}><button type="button" className="btn btn-sm btn-outline-success">Fuente</button></Link>
                      <Link to='https://mpago.la/1giQW6S' style={{paddingLeft: '5px'}}><button type="button" className="btn btn-sm btn-outline-primary">Comprar</button></Link>
                    </div>
                    
                  </div>
                  <small className="text-precio"><br></br>$ 14.000.-</small>
                </div>
              </div>
            </div>
            <div className="col-md-4A">
            <div className="card mb-4 box-shadow">
                <img className="card-img-top" alt="SINGLE-SHOT-LLAVERO-DE-PISTOLA-80S" src="../assets/clientes/2/productos/IMG_0174_wm_i.JPG" data-holder-rendered="true" style={{ width: '100%', display: 'block'}}/>
                <div className="card-body">
                  <p className="card-text">SINGLE SHOT LLAVERO DE PISTOLA 80S</p>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="btn-group">
                      <Link to='https://toytek.cl/products/toytek-single-shot-llavero-de-pistola-80s' target='_blank' style={{paddingLeft: '5px'}}><button type="button" className="btn btn-sm btn-outline-success">Fuente</button></Link>
                      <Link to='https://mpago.la/2XqJSWk' style={{paddingLeft: '5px'}}><button type="button" className="btn btn-sm btn-outline-primary">Comprar</button></Link>
                    </div>
                   
                  </div>
                  <small className="text-precio"><br></br>$ 12.000.-</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>)
}

export default Album;