import 'bootstrap/dist/css/bootstrap.min.css';
/* import '../css/jumbotron.css' */
/* import {Link } from 'react-router-dom'; */
import { LazyLoadImage } from 'react-lazy-load-image-component'

function Banner(props) {

    return (<div className='container' style={{position: 'relative'}} ><LazyLoadImage className='img-fluid' style={{minWidth: '100%', height: 'auto'}} src={require(`../assets/clientes/${props.id}/banner/carousel-1.jpg`)} /></div>)
}

export default Banner;