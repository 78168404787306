
import React, { useEffect, useState } from 'react';
import Header from '../components/header.js';
import Album from '../components/album.js';
import Video from '../components/video2.js';
import Jumbotron from '../components/jumbotron.js';
/* import Circles from '../components/circles.js'; */
import Galeria from '../components/galeria_recuadros.js';
import GaleriaLL from '../components/galeria_recuadrosLL.js';
import Caracteristicas from '../components/caracteristicas.js';
import Host from '../components/host.js';
import FooterA from '../components/footerA.js';
import FooterT from '../components/footerT.js';
import FooterLL from '../components/footerLL.js';
import Banner from '../components/banner.js';
import Info from '../components/info.js';
import InfoLL from '../components/infoLL.js';
import puntos from "../data/points.ts";
import {useParams } from 'react-router-dom';

function MiSitio() {
        const [item, setItem] = useState([]);
        const url = useParams().url;
        const pedirDatoporURL = (url) => {
        return new Promise((resolve, reject) => {
                const item = puntos.find((el) => el.path === url);
                if (item) {
                        resolve(item);
                } else {
                        /* reject({ error: "No se encontro el producto" }); */
                        const url = 'https://www.plaumap.cl';
                        var win = window.open(url, '_parent');
                        win.focus();
                }
        });
    }
    
    useEffect(() => {
        pedirDatoporURL(url)
                .then((res) => {
                        setItem(res);
                })
    }, [url]);
if (item.plantilla === 'plantilla1')
{
return (<div className={item.backGcolor} >
  <Video  nombre="../assets/video/applite.mp4" 
          caption="portada" 
          replay={true} 
    />
        <Header logo={item.logo} url_web={item.url_web}/>
        <Jumbotron descripcion={item.descripcion}  detalle={item.detalle} color={item.JumboColor} url_web={item.url_web}/>
        <Host />
        <Caracteristicas />
        <FooterA color='white' logo={item.logo}/>
        </div>);
        }
else if (item.plantilla === 'plantilla2')
{
return (<div className={item.backGcolor}>
  <Video  nombre="../assets/video/toytek.mp4" 
          caption="portada" 
          replay={true} 
    />
        <Header logo={item.logo} url_web={item.url_web}/>
        <Banner id={'2'}/>
        <Info />
        <Album />
      {/*   <Circles /> */}
        
        <Galeria /><br></br>
        <FooterT color='white' logo={item.logo}/>
        </div>)
        }
        else if (item.plantilla === 'plantilla3')
                {
                return (<div className={item.backGcolor}>
                {/*   <Video  nombre="../assets/video/toytek.mp4" 
                          caption="portada" 
                          replay={true} 
                    /> */}
                        <Header logo={item.logo} url_web={item.url_web}/>
                    {/*     <Banner id={'2'}/> */}
                        <InfoLL />
                     {/*    <Album /> */}
                      {/*   <Circles /> */}
                        
                        <GaleriaLL /><br></br>
                        <FooterLL color='black' logo={item.logo}/>
                        </div>)
                        }

}

export default MiSitio;