import {Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/host.css'
/* import { LazyLoadImage } from 'react-lazy-load-image-component' */

function Host(props) {

    return (<><div className="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center" style={{position: 'relative', color: 'white'}}>
        <h1 className="display-4">Hosting / Web</h1>
        <p className="lead" >Servicio 24/7 los 365 días del año. Si cree necesitar un hosting de alto rendimiento puede consultarnos, además contamos con el desarrollo de sistemas a la medida.</p>
      </div>
      <div className="container" style={{position: 'relative'}}>
      <div className="card-deck mb-3 text-center">
        <div className="card mb-4 box-shadow">
          <div className="card-header">
            <h4 className="my-0 font-weight-normal">Hosting</h4>
          </div>
          <div className="card-body">
            <h1 className="card-title pricing-card-title">$4.500 <small className="text-muted">x Mes</small></h1>
            <ul className="list-unstyled mt-3 mb-4">
              <li>1 usuario</li>
              <li>2 GB de espacio</li>
              <li>5 o más Emails</li>
              <li>Migración gratis</li>
              <li>Activación gratis</li>
            </ul>
            <Link to="https://applite.cl/contacto" target='_blank'><button type="button" className="btn btn-lg btn-block btn-outline-primary">Contactenos</button></Link>
          </div>
        </div>
        <div className="card mb-4 box-shadow">
          <div className="card-header">
            <h4 className="my-0 font-weight-normal">Hosting</h4>
          </div>
          <div className="card-body">
            <h1 className="card-title pricing-card-title">$45.000 <small className="text-muted">x Año</small></h1>
            <ul className="list-unstyled mt-3 mb-4">
              <li>1 usuario</li>
              <li>2 GB de espacio</li>
              <li>5 o más Emails</li>
              <li>Migración gratis</li>
              <li>Activación gratis</li>
            </ul>
            <Link to="https://applite.cl/contacto" target='_blank'><button type="button" className="btn btn-lg btn-block btn-outline-primary">Contactenos</button></Link>
          </div>
        </div>
        <div className="card mb-4 box-shadow">
          <div className="card-header">
            <h4 className="my-0 font-weight-normal">Hosting + Sitio Web</h4>
          </div>
          <div className="card-body" style={{backgroundColor: 'yellow'}}>
            <h1 className="card-title pricing-card-title">$80.000 <small className="text-muted">x Año</small></h1>
            <ul className="list-unstyled mt-3 mb-4">
              <li>1 usuario</li>
              <li>2 GB de espacio</li>
              <li>5 o más Emails</li>
              <li>3 Páginas Web / Whatsapp</li>
              <li>Activación gratis</li>
            </ul>
            <Link to="https://applite.cl/contacto" target='_blank'><button type="button" className="btn btn-lg btn-block btn-outline-primary">Consultar Oferta</button></Link>
          </div>
        </div>
      </div>
    </div></>)
}

export default Host;