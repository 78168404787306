import React from 'react'
import '../css/Video.css';
function Video(props) {
 return (
<div className={props.caption}>
  <video id='video1' loop={props.replay} muted autoPlay>
    <source src={props.nombre} type = 'video/mp4' />
  </video>  
    {props.string}
</div>
 );
}
export default Video;