/* import React, { useEffect, useState } from 'react'; */
/* import data from "../data/categorias.json"; */
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import '../css/foodhut.css';
import '../css/galeria_recuadros.css';
function App() {

    return (<><div className="container" style={{justifyContent: 'center', alignItems: 'center'}}>
        <div className="gallary row">
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn" >
                <LazyLoadImage src="../assets/categorias/cat-1.jpg" alt="figuras-de-accion-de-coleccion-retro" className="gallary-img"/>
                <Link to="https://toytek.cl/collections/figuras-de-accion" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                    <p>Figuras de acción</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/categorias/cat-2.jpg" alt="figuras-articuladas-de-coleccion-retro" className="gallary-img" />
                <Link to="https://toytek.cl/collections/figuras-articuladas" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Figuras articuladas</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/categorias/cat-3.jpg" alt="autitos-antiguos-de-coleccion-retro" className="gallary-img" />
                <Link to="https://toytek.cl/collections/autitos" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Autitos</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/categorias/cat-4.jpg" alt="mini-figuras-monocromaticas-de-coleccion-retro" className="gallary-img" />
                <Link to="https://toytek.cl/collections/figuras-monocromaticas" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Monocromáticas</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/categorias/cat-5.jpg" alt="papeleria-de-coleccion-retro" className="gallary-img" />
                <Link to="https://toytek.cl/collections/papeleria" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Papelería</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/categorias/cat-6.jpg" alt="videos-de-coleccion-retro" className="gallary-img" />
                <Link to="https://toytek.cl/collections/peliculas" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Videos</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/categorias/cat-7.jpg" alt="figuras-plasticas-de-coleccion-retro" className="gallary-img" />
                <Link to="https://toytek.cl/collections/figuras-plasticas" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Figuras Plásticas</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/categorias/cat-8.jpg" alt="promocionales-de-coleccion-retro" className="gallary-img" />
                <Link to="https://toytek.cl/collections/promocionales" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Promocionales</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/categorias/cat-9.jpg" alt="figuras-de-pvc-de-coleccion-retro" className="gallary-img" />
                <Link to="https://toytek.cl/collections/figuras-de-pvc" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Figuras de PVC</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/categorias/cat-10.jpg" alt="rompecabezas-de-coleccion-retro" className="gallary-img" />
                <Link to="https://toytek.cl/collections/rompecabezas" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Rompecabezas</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/categorias/cat-11.jpg" alt="videojuegos-de-coleccion-retro" className="gallary-img" />
                <Link to="https://toytek.cl/collections/videojuegos" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>VideoJuegos</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/categorias/cat-12.jpg" alt="variados-de-coleccion-retro" className="gallary-img" />
                <Link to="https://toytek.cl/collections/variados" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Variados</p>
                </Link>
            </div>
        </div></div></>);
}

export default App;
