import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Portada from './pages/Portada.js';
import Sitios from './components/Sitio.js';
import Pagar from './components/Pagos.js';
/* import Pagos2 from './components/Pagos2.js'; */
import PagoEnProceso from './pages/Pagos-pago-en-proceso.js';
import PagoExitoso from './pages/Pagos-pago-exitoso.js';
function App() {
  return (
   <div>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Portada />} />
        <Route path="/sitios/:url" element={<Sitios />} />
        <Route path="/pagos/:code" element={<Pagar />} />
        <Route path="/pagos/:code/pago-en-proceso" element={<PagoEnProceso />} />
        <Route path="/pagos/:code/pago-exitoso" element={<PagoExitoso />} />
        <Route path="*" element={<Navigate to="/"/>} />
      </Routes>
    </BrowserRouter></div>
  );
}

export default App;