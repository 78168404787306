import {Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/info.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'

function Info(props) {

    return (<div className='container' style={{paddingTop: '25px'}}><div className="rowInfo mb-2">
<div className="col-md-6">
  <div className="rowInfo g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
    <div className="col p-4 d-flex flex-column position-static"  style={{backgroundColor: 'white'}}>
      <strong className="d-inline-block mb-2 text-primary-emphasis">COMPRO - CAMBIO</strong>
      <h3 className="mb-0">DISEÑOS A PEDIDO</h3>
      <div className="mb-1 text-body-secondary">ABONO 50%</div>
      <p className="card-text mb-auto" style={{color:'black'}}>Visita mi Instagram.</p>
      <Link to="https://www.instagram.com/lluvia._store" target='_blank'>
        <p className="btn btn-sm btn-outline-primary">ir a mi Instagram</p>
      </Link>
    </div>
    <div className="col-auto d-none d-lg-block"  style={{backgroundColor: 'white'}}>
    <LazyLoadImage src='../assets/clientes/3/info/1.jpg' />
    </div>
  </div>
</div>
<div className="col-md-6">
  <div className="rowInfo g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
    <div className="col p-4 d-flex flex-column position-static"  style={{backgroundColor: 'white'}}>
      <strong className="d-inline-block mb-2 text-success-emphasis">CONTACTAME</strong>
      <h3 className="mb-0">Whatsapp</h3>
      <div className="mb-1 text-body-secondary">Ingresa al sitio Web</div>
      <p className="card-text mb-auto" style={{color:'black'}}>Escribeme al Whatsapp</p>
      <Link to="#" target='_blank'>
        <p className="btn btn-sm btn-outline-primary">ir al Whatsapp</p>
      </Link>
    </div>
    <div className="col-auto d-none d-lg-block"  style={{backgroundColor: 'white'}}>
    <LazyLoadImage src='../assets/clientes/3/info/2.jpg' />
    </div>
  </div>
</div>
</div></div>)
}

export default Info;