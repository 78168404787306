/* import {Link } from 'react-router-dom'; */
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/header.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'

function Header(props) {

    return (<div className='container'><header className="py-3">
<div className="row flex-nowrap justify-content-between align-items-center">
  <div className="col-4 pt-1">
    {/* <Link className="text-muted" to={props.url_web} target='_blank'>{props.url_web}</Link> */}
  </div>
  <div className="col-4 text-center">
    <LazyLoadImage className="blog-header-logo text-dark img-fluid" style={{width:'150px'}} src={`${props.logo}`} />
  </div>
  <div className="col-4 d-flex justify-content-end align-items-center">
   {/*  <Link className="text-muted" to="#">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="mx-3"><circle cx="10.5" cy="10.5" r="7.5"></circle><line x1="21" y1="21" x2="15.8" y2="15.8"></line></svg>
    </Link> */}
   {/*  <Link className="btn btn-sm btn-outline-secondary" to="#">Sign up</Link> */}
  </div>
</div>
</header></div>)
}

export default Header;