
import React, { useEffect, useState } from 'react';
import productos from "../data/productos/2.json";
import {useParams } from 'react-router-dom';

function PagoEnProceso() {
        const [item, setItem] = useState([]);
        const code = useParams().code;
        const pedirDatoporURL = (code) => {
        return new Promise((resolve, reject) => {
                const item = productos.find((el) => el.code === code);
                if (item) {
                        resolve(item);
                       /*  window.open(item.link_de_pago, '_parent').focus(); */
                } else {
                        /* reject({ error: "No se encontro el producto" }); */
                        const url = 'https://plaumap.cl/no-encontrado';
                        window.open(url, '_parent').focus();
                }
        });
    }
    
    useEffect(() => {
        pedirDatoporURL(code)
                .then((res) => {
                        setItem(res);
                       
                })
    }, [code]);

    
return (
        <><img src={`${item.foto1}.JPG`} /></>
      );
}

export default PagoEnProceso;