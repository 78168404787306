import {Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/header.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'

function Nav(props) {

    return (<div className="nav-scroller py-1 mb-3 border-bottom">
    <nav className="nav nav-underline justify-content-between">
      <Link className="nav-item nav-link link-body-emphasis active" to="#">World</Link>
        <form id='formFilter'>
            <select class="form-select form-select-sm mb-3" aria-label=".form-select-sm example">
                <option selected>Seleccione</option>
                <option value="1" disabled>Botillerias</option>
                <option value="2" disabled>Comida al Paso</option>
                <option value="3" disabled>Ferias</option>
                <option value="3" disabled>Fruterías</option>
                <option value="4" disabled>Librerías</option>
                <option value="5" disabled>Minimarket</option>
                <option value="6" disabled>Panaderías</option>
                <option value="7" disabled>Peluquerías</option>
            </select>
        </form>
      <Link className="nav-item nav-link link-body-emphasis" to="#">Technology</Link>
      <Link className="nav-item nav-link link-body-emphasis" to="#">Design</Link>
{/*       <Link className="nav-item nav-link link-body-emphasis" to="#">Culture</Link>
      <Link className="nav-item nav-link link-body-emphasis" to="#">Business</Link>
      <Link className="nav-item nav-link link-body-emphasis" to="#">Politics</Link>
      <Link className="nav-item nav-link link-body-emphasis" to="#">Opinion</Link>
      <Link className="nav-item nav-link link-body-emphasis" to="#">Science</Link>
      <Link className="nav-item nav-link link-body-emphasis" to="#">Health</Link>
      <Link className="nav-item nav-link link-body-emphasis" to="#">Style</Link>
      <Link className="nav-item nav-link link-body-emphasis" to="#">Travel</Link> */}
    </nav>
  </div>)
}

export default Nav;