
import React, { useEffect, useState } from 'react';
import Header from '../components/header.js';
import Album from '../components/album.js';
import Jumbotron from '../components/jumbotron.js';
import Circles from '../components/circles.js';
import Galeria from '../components/galeria_recuadros.js';
import Caracteristicas from '../components/caracteristicas.js';
import Host from '../components/host.js';
import Footer from '../components/footer.js';
import Banner from '../components/banner.js';
import productos from "../data/productos/2.json";
import {useParams } from 'react-router-dom';

function MiSitio() {
        const [item, setItem] = useState([]);
        const code = useParams().code;
        const pedirDatoporURL = (code) => {
        return new Promise((resolve, reject) => {
                const item = productos.find((el) => el.code === code);
                if (item) {
                        resolve(item);
                       /*  window.open(item.link_de_pago, '_parent').focus(); */
                } else {
                        /* reject({ error: "No se encontro el producto" }); */
                        const url = 'https://www.plaumap.cl/no-encontrado';
                        window.open(url, '_parent').focus();
                }
        });
    }
    
    useEffect(() => {
        pedirDatoporURL(code)
                .then((res) => {
                        setItem(res);
                       
                })
    }, [code]);

    
return (
        <><img src={`${item.foto1}.JPG`} /></>
      );
}

export default MiSitio;