type RawTree = [string, number, number, string, string, string, string, string, string, string, string, string, number, google.maps.LatLngLiteral, string, string, string, string, string, string];

type Puntos = {
    key: string;
    name: string;
    lat: number;
    lng: number;
    emp: string;
    url_web: string;
    detalle: string;
    url_instagram: string;
    url_facebook: string;
    url_youtube: string;
    url_tiktok: string;
    descripcion: string;
    logo: string;
    ico: number;
    location : google.maps.LatLngLiteral;
    path: string;
    backGcolor: string;
    JumboColor: string;
    plantilla: string;
    telefono: string;
    correo: string;
}

const trees: RawTree[] = [
    ["APP-LITE", -33.115575583371005, -71.57776902129329,
        "APP-LITE",
        "https://applite.cl", 
        "SERVICIO WEB + HOSTING",
        "", 
        "https://www.facebook.com/profile.php?id=61554394417815", 
        "https://www.youtube.com/@applitecl", 
        "", 
        "Web y Sistemas a medida, Hosting.",
        "https://www.applite.cl/assets/logo/applite.png", 
        1,
        {lat: -33.115575583371005, lng: -71.57776902129329}
        ,"applite-sitios-web-sistemas-a-la-medida-hosting", "", "JumboBackG_APPLITE", "plantilla1","+56 9 9930 2909", "applite.chile@gmail.com"
],
    ["TOYTEK", -33.11563131930962, -71.57777736337314,
        "TOYTEK", 
        "https://toytek.cl", 
        "COMPRO - CAMBIO - VENDO",
        "https://www.instagram.com/toytek.cl/", 
        "https://www.facebook.com/toytek.cl/", 
        "https://www.youtube.com/@toytek_cl", 
        "", 
        "Juguetes retro y de colección.",
        "https://www.toytek.cl/assets/logo/toytek.png", 
        2,
        {lat: -33.11563131930962, lng: -71.57777736337314},
        "toytek-juguetes-de-coleccion-retro",
        "backGcolor_TOYTEK", "JumboBackG_TOYTEK", "plantilla2","+56 9 9930 2909", "toytekcl@gmail.com"]
        ,
    ["LLUVIA STORE", -33.11516311122478, -71.57763607235117,
        "LLUVIA STORE", 
        "https://www.instagram.com/lluvia._store", 
        "COMPRO - CAMBIO - VENDO",
        "", 
        "", 
        "", 
        "", 
        "Taller de diseño, confección y sublimación de prendas y artículos personalizados.",
        "https://www.plaumap.cl/assets/clientes/3/logo/3.png", 
        2,
        {lat: -33.11516311122478, lng: -71.57763607235117},
        "lluvia-store-taller-diseno-confeccion-y-sublimacion-de-prendas-y-articulos-personalizados",
        "", "", "plantilla3","", ""]
]

const formatted: Puntos[] = trees.map(([name, lat, lng, emp, url_web, detalle, url_instagram, url_facebook, url_youtube, url_tiktok, descripcion, logo, ico, location, path, backGcolor, JumboColor, plantilla, telefono, correo]) => ({
    name, lat, lng, emp, url_web, detalle, url_instagram, url_facebook, url_youtube, url_tiktok, descripcion, logo, ico, location, path, backGcolor, JumboColor, plantilla, telefono, correo,
    key: JSON.stringify({name, lat, lng, emp, url_web, detalle, url_instagram, url_facebook, url_youtube, url_tiktok, descripcion, logo, ico, location, path, backGcolor, JumboColor, plantilla, telefono, correo})
}));

export default formatted;