/* import React, { useEffect, useState } from 'react'; */
/* import data from "../data/categorias.json"; */
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import '../css/foodhut.css';
import '../css/galeria_recuadros.css';
function App() {

    return (<><div className="container" style={{justifyContent: 'center', alignItems: 'center'}}>
    <h5 id="pantalones" style={{color: 'black', fontSize: 38, paddingTop: '10px'}}>Pantalones</h5>
        <div className="gallary row">
 
        <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn" >
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="figuras-de-accion-de-coleccion-retro" className="gallary-img"/>
                <Link to="https://www.instagram.com/p/CmNXNVfJg4b/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                    <p>Disponibles para entrega inmediata</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="poleron-de-polar-patchwork-con-cuello" className="gallary-img" />
                <Link to="https://www.instagram.com/p/CmNVBwWplwV/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Poleron de polar patchwork con cuello</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="autitos-antiguos-de-coleccion-retro" className="gallary-img" />
                <Link to="https://www.instagram.com/p/CmNVBwWplwV/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Poleron de polar patchwork con cuello</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="mini-figuras-monocromaticas-de-coleccion-retro" className="gallary-img" />
                <Link to="https://www.instagram.com/p/CmNVBwWplwV/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Poleron de polar patchwork con cuello</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="papeleria-de-coleccion-retro" className="gallary-img" />
                <Link to="https://www.instagram.com/p/CmNVBwWplwV/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Poleron de polar patchwork con cuello</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="videos-de-coleccion-retro" className="gallary-img" />
                <Link to="https://www.instagram.com/p/CmNVBwWplwV/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Poleron de polar patchwork con cuello</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="figuras-plasticas-de-coleccion-retro" className="gallary-img" />
                <Link to="https://www.instagram.com/p/CmNVBwWplwV/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Poleron de polar patchwork con cuello</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="promocionales-de-coleccion-retro" className="gallary-img" />
                <Link to="https://www.instagram.com/p/CmNVBwWplwV/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Poleron de polar patchwork con cuello</p>
                </Link>
            </div>
        </div>
        <h5 id="polerones" style={{color: 'black', fontSize: 38, paddingTop: '10px'}}>Polerones</h5>
        <div className="gallary row">
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn" >
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="figuras-de-accion-de-coleccion-retro" className="gallary-img"/>
                <Link to="https://www.instagram.com/p/CmNXNVfJg4b/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                    <p>Disponibles para entrega inmediata</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="poleron-de-polar-patchwork-con-cuello" className="gallary-img" />
                <Link to="https://www.instagram.com/p/CmNVBwWplwV/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Poleron de polar patchwork con cuello</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="autitos-antiguos-de-coleccion-retro" className="gallary-img" />
                <Link to="https://www.instagram.com/p/CmNVBwWplwV/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Poleron de polar patchwork con cuello</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="mini-figuras-monocromaticas-de-coleccion-retro" className="gallary-img" />
                <Link to="https://www.instagram.com/p/CmNVBwWplwV/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Poleron de polar patchwork con cuello</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="papeleria-de-coleccion-retro" className="gallary-img" />
                <Link to="https://www.instagram.com/p/CmNVBwWplwV/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Poleron de polar patchwork con cuello</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="videos-de-coleccion-retro" className="gallary-img" />
                <Link to="https://www.instagram.com/p/CmNVBwWplwV/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Poleron de polar patchwork con cuello</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="figuras-plasticas-de-coleccion-retro" className="gallary-img" />
                <Link to="https://www.instagram.com/p/CmNVBwWplwV/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Poleron de polar patchwork con cuello</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="promocionales-de-coleccion-retro" className="gallary-img" />
                <Link to="https://www.instagram.com/p/CmNVBwWplwV/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Poleron de polar patchwork con cuello</p>
                </Link>
            </div>
        </div>
        <h5 id="polerones" style={{color: 'black', fontSize: 38, paddingTop: '10px'}}>Sublimación</h5>
        <div className="gallary row">
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn" >
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="figuras-de-accion-de-coleccion-retro" className="gallary-img"/>
                <Link to="https://www.instagram.com/p/CmNXNVfJg4b/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                    <p>Disponibles para entrega inmediata</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="poleron-de-polar-patchwork-con-cuello" className="gallary-img" />
                <Link to="https://www.instagram.com/p/CmNVBwWplwV/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Poleron de polar patchwork con cuello</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="autitos-antiguos-de-coleccion-retro" className="gallary-img" />
                <Link to="https://www.instagram.com/p/CmNVBwWplwV/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Poleron de polar patchwork con cuello</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="mini-figuras-monocromaticas-de-coleccion-retro" className="gallary-img" />
                <Link to="https://www.instagram.com/p/CmNVBwWplwV/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Poleron de polar patchwork con cuello</p>
                </Link>
            </div>
        </div>
        <h5 id="polerones" style={{color: 'black', fontSize: 38, paddingTop: '10px'}}>Tazas</h5>
        <div className="gallary row">
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn" >
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="figuras-de-accion-de-coleccion-retro" className="gallary-img"/>
                <Link to="https://www.instagram.com/p/CmNXNVfJg4b/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                    <p>Disponibles para entrega inmediata</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="poleron-de-polar-patchwork-con-cuello" className="gallary-img" />
                <Link to="https://www.instagram.com/p/CmNVBwWplwV/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Poleron de polar patchwork con cuello</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="autitos-antiguos-de-coleccion-retro" className="gallary-img" />
                <Link to="https://www.instagram.com/p/CmNVBwWplwV/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Poleron de polar patchwork con cuello</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="mini-figuras-monocromaticas-de-coleccion-retro" className="gallary-img" />
                <Link to="https://www.instagram.com/p/CmNVBwWplwV/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Poleron de polar patchwork con cuello</p>
                </Link>
            </div>
        </div>
        <h5 id="polerones" style={{color: 'black', fontSize: 38, paddingTop: '10px'}}>Peluches</h5>
        <div className="gallary row">
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn" >
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="figuras-de-accion-de-coleccion-retro" className="gallary-img"/>
                <Link to="https://www.instagram.com/p/CmNXNVfJg4b/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                    <p>Disponibles para entrega inmediata</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="poleron-de-polar-patchwork-con-cuello" className="gallary-img" />
                <Link to="https://www.instagram.com/p/CmNVBwWplwV/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Poleron de polar patchwork con cuello</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="autitos-antiguos-de-coleccion-retro" className="gallary-img" />
                <Link to="https://www.instagram.com/p/CmNVBwWplwV/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Poleron de polar patchwork con cuello</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="mini-figuras-monocromaticas-de-coleccion-retro" className="gallary-img" />
                <Link to="https://www.instagram.com/p/CmNVBwWplwV/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Poleron de polar patchwork con cuello</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="papeleria-de-coleccion-retro" className="gallary-img" />
                <Link to="https://www.instagram.com/p/CmNVBwWplwV/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Poleron de polar patchwork con cuello</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="videos-de-coleccion-retro" className="gallary-img" />
                <Link to="https://www.instagram.com/p/CmNVBwWplwV/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Poleron de polar patchwork con cuello</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="figuras-plasticas-de-coleccion-retro" className="gallary-img" />
                <Link to="https://www.instagram.com/p/CmNVBwWplwV/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Poleron de polar patchwork con cuello</p>
                </Link>
            </div>
            <div className="col-sm-6 col-lg-3 gallary-item wow fadeIn">
                <LazyLoadImage src="../assets/clientes/3/polerones/2.jpg" alt="promocionales-de-coleccion-retro" className="gallary-img" />
                <Link to="https://www.instagram.com/p/CmNVBwWplwV/" target="_blank" className="gallary-overlay" style={{color: 'white', fontSize: 24}}>
                <p>Poleron de polar patchwork con cuello</p>
                </Link>
            </div>
        </div>
        </div></>);
}

export default App;
