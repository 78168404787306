import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/host.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'

function Footer(props) {

  return (<div className="container" style={{paddingBottom:'5px'}}>
    <footer className="pt-4 my-md-5 pt-md-5 border-top">
      <div className="row">
        <div className="col-12 col-md">
          <LazyLoadImage className="mb-2" src={props.logo} alt="" style={{width:'150px'}}/>
          <small className="d-block mb-3 text-muted">© 2024</small>
        </div>
        <div className="col-6 col-md">
          <h5 style={{color: props.color}}>Categorías</h5>
          <ul className="list-unstyled text-small">
            <li><Link className="text-muted" to="">Inicio</Link></li>
            <li><Link className="text-muted" to="">Guía</Link></li>
            <li><Link className="text-muted" to="">Mapa</Link></li>
            
          </ul>
        </div>
        <div className="col-6 col-md">
          <h5 style={{color: props.color}}>+</h5>
          <ul className="list-unstyled text-small">
            <li><Link className="text-muted" to="">Avisos</Link></li>
            <li><Link className="text-muted" to="">Publicita Aquí</Link></li>
            <li><Link className="text-muted" to="">Contacto</Link></li>
          </ul>
        </div>
        <div className="col-6 col-md">
          <h5 style={{color: props.color}}>+</h5>
          <ul className="list-unstyled text-small">
            <li><Link className="text-muted" to="">Reglas</Link></li>
            <li><Link className="text-muted" to="">Trabaja con Nosotros</Link></li>
            <li><Link className="text-muted" to=""></Link></li>
          </ul>
        </div>
      </div>
    </footer>
  </div>)
}

export default Footer;