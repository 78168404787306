
/* import Header from '../components/header.js';
import Album from '../components/album.js';
import Jumbotron from '../components/jumbotron.js';
import Circles from '../components/circles.js';
import Galeria from '../components/galeria_recuadros.js'; */
import { LazyLoadImage } from 'react-lazy-load-image-component';
import '../css/caracteristicas.css'

function Caracteristicas() {


return (<div className='container'><hr className="featurette-divider" />

<div className="rowCaract featurette">
  <div className="col-md-7">
    <h2 className="featurette-heading fw-normal lh-1">Migración de Sistemas. <span className="text-body-secondary">HOSTING EMPRENDEDOR.</span></h2>
    <p className="lead">Hosting para páginas y sitios web.</p>
  </div>
  <div className="col-md-5">
    <LazyLoadImage src="../assets/categorias/migracion.jpg"/>
  </div>
</div>

<hr className="featurette-divider" />

<div className="rowCaract featurette">
  <div className="col-md-7 order-md-2">
    <h2 className="featurette-heading fw-normal lh-1">Programas, Base de datos. <span className="text-body-secondary">¡DESARROLLOS ADAPTABLES A DISPOSITIVOS!</span></h2>
    <p className="lead">Análisis, Diseño, Desarrollo, Integración, Migración, Mantención, Soporte, Respaldos, Mejoras y Actualizaciones Web.</p>
  </div>
  <div className="col-md-5 order-md-1">
  <LazyLoadImage src="../assets/categorias/bbdd.jpg"/>
  </div>
</div>

<hr className="featurette-divider" />

<div className="rowCaract featurette">
  <div className="col-md-7">
    <h2 className="featurette-heading fw-normal lh-1">Optimización en buscadores. <span className="text-body-secondary">Servicios SEO.</span></h2>
    <p className="lead">Open Graph, reCAPTCHA, Google Search Console, Google Maps, Servicios API REST.</p>
  </div>
  <div className="col-md-5">
    <LazyLoadImage src="../assets/categorias/seo.jpg"/>
  </div>
</div>

<hr className="featurette-divider" /></div>);
}

export default Caracteristicas;