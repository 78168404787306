import React, { useState} from 'react';
import { Link } from 'react-router-dom';
import puntos from "../data/points.ts";
import {
    APIProvider,
    Map,
    AdvancedMarker,
    Pin,
    InfoWindow/* ,
    MapControl */
} from '@vis.gl/react-google-maps';
/* import { MarkerClusterer } from "@googlemaps/markerclusterer";
import type { Marker } from "@googlemaps/markerclusterer"; */
import credenciales from "./credenciales.js";
/* import { StreetViewPanorama } from "react-google-maps"; */
/* import * as Icon from 'react-bootstrap-icons';
import  {FaFileCode }  from "react-icons/fa"; */
/* import {LazyLoadImage}  from 'react-lazy-load-image-component'; */
/* import "../css/map.css"; */
const center1 = {
    lat: -33.1226255628576,
    lng: -71.5766653932441
}

export default function Intro() {
    
    /* 
        const position = {lat: -33.11556845106588, lng: -71.57779030995384}; */
    return (<APIProvider apiKey={credenciales.mapsKey}>
        <div style={{ height: '100vh', width: '100%' }} >
            <Map defaultZoom={15} defaultCenter={center1} mapId={credenciales.idMap} mapTypeControl={false} maxZoom={18} minZoom={14} fullscreenControl={true} streetViewControl={false}>
                <Markers points={puntos} />
            </Map>
        </div>
    </APIProvider>);

}

type Point = { key: string, url_web: string, url_facebook: string, url_instagram: string, url_youtube: string, location: google.maps.LatLngLiteral, ico: number, logo: string, path: string, descripcion: string};
type Props = { points: Point[] };


const Markers = ({ points }: Props) => {
    /*     const [selectedMarker, setSelectedMarker] = useState(""); */
/*     const [selectedMarker, setSelectedMarker] = useState(false); */
    const [selectedMarkerId, setSelectedMarkerId] = useState(null);


    const handleMarkerClick = (id) => {
        setSelectedMarkerId(id);
      };

      const handleCloseClick = () => {
        setSelectedMarkerId(null);
      };

    const IconoPunto = (props) => {
        if(props.ico === 1)
            return <Pin background={"#6990fc"} borderColor={"#012483"} glyphColor={"#012483"} scale={1} />
        else if(props.ico === 2)
            return <Pin background={"#ff0800"} borderColor={"#931f1c"} glyphColor={"#931f1c"} scale={0.7} />
        else 
        return <Pin background={"#5bea00"} borderColor={"#68b636"} glyphColor={"#68b636"} scale={0.5} />
    }
    return <>
        {points.map((point) => <AdvancedMarker position={point.location} key={point.key} onClick={() => handleMarkerClick(point.key)}>
            {/*    <span>
                <Icon.Cpu id='volumen' alignmentBaseline='central' size={25} color='red' 
               ></Icon.GeoAltFill>
            </span> */}
            {/* <Pin
                background={"#809ff4"}
                borderColor={"yellow"}
                glyphColor={"white"} /> */}
            
            <IconoPunto ico={point.ico}></IconoPunto>
          {/*  <Icon.GeoAltFill alignmentBaseline='central' size={30} color='red'></Icon.GeoAltFill> */}

        </AdvancedMarker>
  ) }
             {selectedMarkerId !== null && (<InfoWindow key={selectedMarkerId} position={points.find((point) => point.key === selectedMarkerId)?.location}
          onCloseClick={handleCloseClick} >
<div style={{ width: '100%', color: 'black' }}>
    <img style={{border: '0', width: '90px'}} className="img-fluid" src={`${points.find((point) => point.key === selectedMarkerId)?.logo}`}  alt="" key={selectedMarkerId}/>
    <p>{points.find((point) => point.key === selectedMarkerId)?.descripcion}</p>
    <Link to={`/sitios/${points.find((point) => point.key === selectedMarkerId)?.path}`} target='_blank'><button className="btn btn-sm btn-outline-primary">+Más</button></Link>
   {/*  <Link to={`${points.find((point) => point.key === selectedMarkerId)?.url_web}`} target='_blank' ><Icon.Link45deg alignmentBaseline='central' size={20} color='blue'></Icon.Link45deg></Link>
    <Link to={`${points.find((point) => point.key === selectedMarkerId)?.url_facebook}`} target='_blank'><Icon.Facebook alignmentBaseline='central' size={20} color='blue'></Icon.Facebook></Link>
    <Link to={`${points.find((point) => point.key === selectedMarkerId)?.url_instagram}`} target='_blank'><Icon.Instagram alignmentBaseline='central' size={20} color='blue'></Icon.Instagram></Link>
    <Link to={`${points.find((point) => point.key === selectedMarkerId)?.url_youtube}`} target='_blank'><Icon.Youtube alignmentBaseline='central' size={20} color='blue'></Icon.Youtube></Link> */}
</div>
</InfoWindow>)}
   </>

    
};