import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/host.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import * as Icon from 'react-bootstrap-icons';

function Host(props) {

  return (<div className="container" style={{paddingBottom:'5px'}}>
    <footer className="pt-4 my-md-5 pt-md-5 border-top">
      <div className="row">
        <div className="col-12 col-md">
          <LazyLoadImage className="mb-2" src={props.logo} alt="" style={{width:'150px'}}/>
          <small className="d-block mb-3" style={{color: props.color, fontWeight: 'bold'}}>© 2024</small>
          <p><Link  style={{color: props.color}} to="https://www.youtube.com/@toytek_cl" target="_blank"><Icon.Youtube size={24}></Icon.Youtube> https://www.youtube.com/@toytek_cl</Link></p>
          <p><Link  style={{color: props.color}} to="https://www.instagram.com/toytek.cl/" target="_blank"><Icon.Instagram size={24}></Icon.Instagram> https://www.instagram.com/toytek.cl/</Link></p>
          <p><Link  style={{color: props.color}} to="https://www.facebook.com/toytek.cl/" target="_blank"><Icon.Facebook size={24}></Icon.Facebook> https://www.facebook.com/toytek.cl/</Link></p>
          <p><Link  style={{color: props.color}} to="mailto:toytekcl@gmail.com"><Icon.MailboxFlag size={24}></Icon.MailboxFlag> toytekcl@gmail.com</Link></p>
        </div>
        <div className="col-6 col-md">
          <h5 style={{color: props.color, fontWeight: 'bold'}}>Categorías</h5>
          <ul className="list-unstyled text-small">
            <li><Link  style={{color: props.color, fontWeight: 'bold'}} to="https://toytek.cl/collections/figuras-de-accion" target="_blank">Acción</Link></li>
            <li><Link  style={{color: props.color, fontWeight: 'bold'}} to="https://toytek.cl/collections/figuras-articuladas" target="_blank">Articuladas</Link></li>
            <li><Link  style={{color: props.color, fontWeight: 'bold'}} to="https://toytek.cl/collections/autitos" target="_blank">Autitos</Link></li>
            <li><Link  style={{color: props.color, fontWeight: 'bold'}} to="https://toytek.cl/collections/figuras-monocromaticas" target="_blank">Monocromáticas</Link></li>
          </ul>
        </div>
        <div className="col-6 col-md">
          <h5 style={{color: props.color, fontWeight: 'bold'}}>+</h5>
          <ul className="list-unstyled text-small">
            <li><Link  style={{color: props.color, fontWeight: 'bold'}} to="https://toytek.cl/collections/papeleria" target="_blank">Papelería</Link></li>
            <li><Link  style={{color: props.color, fontWeight: 'bold'}} to="https://toytek.cl/collections/peliculas" target="_blank">Películas</Link></li>
            <li><Link  style={{color: props.color, fontWeight: 'bold'}} to="https://toytek.cl/collections/figuras-plasticas" target="_blank">Plásticas</Link></li>
            <li><Link  style={{color: props.color, fontWeight: 'bold'}} to="https://toytek.cl/collections/promocionales" target="_blank">Promocionales</Link></li>
          </ul>
        </div>
        <div className="col-6 col-md">
          <h5 style={{color: props.color, fontWeight: 'bold'}}>+</h5>
          <ul className="list-unstyled text-small">
            <li><Link  style={{color: props.color, fontWeight: 'bold'}} to="https://toytek.cl/collections/figuras-de-pvc" target="_blank">PVC</Link></li>
            <li><Link  style={{color: props.color, fontWeight: 'bold'}} to="https://toytek.cl/collections/rompecabezas" target="_blank">Rompecabezas</Link></li>
            <li><Link  style={{color: props.color, fontWeight: 'bold'}} to="https://toytek.cl/collections/videojuegos" target="_blank">Videojuegos</Link></li>
            <li><Link  style={{color: props.color, fontWeight: 'bold'}} to="https://toytek.cl/collections/variados" target="_blank">Variados</Link></li>
          </ul>
        </div>
      </div>
    </footer>
  </div>)
}

export default Host;