import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/jumbotron.css'
import { Link } from 'react-router-dom';


function Jumbotron(props) {

  return (<div className='container' style={{position: 'relative'}}>
            <div className={`jumbotron p-3 p-md-5 text-white rounded bg-dark ${props.color}`}>
              <div className="col-md-6 px-0 text-white" >
                <h1 className="display-5 font-italic">{props.descripcion}</h1>
                <p className="lead my-3">{props.detalle}</p>
                <Link className="text-muted" to={props.url_web} target='_blank'><p className="btn btn-sm btn-outline-primary">ir al Sitio Web</p></Link>
              </div>
            </div>
          </div>)
}

export default Jumbotron;